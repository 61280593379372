import { template as template_97dc1bdbc4534f1dad287bfd75bb7452 } from "@ember/template-compiler";
const SidebarSectionMessage = template_97dc1bdbc4534f1dad287bfd75bb7452(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
