import { template as template_a057ef6442d1403a8285e23aaf434e7c } from "@ember/template-compiler";
const FKLabel = template_a057ef6442d1403a8285e23aaf434e7c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
