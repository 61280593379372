import { template as template_427bfb45ef6c4c239003354ae2ab7ed5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_427bfb45ef6c4c239003354ae2ab7ed5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
